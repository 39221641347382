import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import Home from "./pages/Home.js";
import ContactPage from "./pages/ContactPage.js";
import InitiativePage from "./pages/InitiativePage.js";
import BlogPage from "./pages/BlogPage.js";
import AboutUsPage from "./pages/AboutUsPage.js";
import GalleryPage from "./pages/GalleryPage.js";
import JoinUsPage from "./pages/JoinUsPage.js";
import Footer from "./components/Footer.js";
import Header from "./components/Header.js";
import DeveloperLogin from "./pages/DeveloperLogin.js"; // Add the login page
import { getAccessToken } from "./apis/APIhandler.js";

function App() {
  const [isTab, setIsTab] = useState(true);
  const [timer, setTimer] = useState(60 * 60);
  const [timerOn, setTimerOn] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  let refreshToken = localStorage.getItem("refreshToken");

  useEffect(() => {
    const checkScreenSize = () => {
      const screenWidth = window.innerWidth;
      const minDesktopWidth = 500; // Define minimum width for desktop view

      setIsTab(screenWidth <= minDesktopWidth || screenWidth >= 1025);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  useEffect(() => {
    let interval;
    if (timerOn) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 60) {
            clearInterval(interval);
            setTimerOn(false);
            getAccessToken(refreshToken)
              .then((response) => {
                const accessToken = response.data.accessToken;
                sessionStorage.setItem("accessToken", accessToken);
                setTimer(60 * 60);
                setTimerOn(true);
              })
              .catch((error) => {
                console.error("Failed to refresh access token:", error);
                setTimer(60 * 60);
                setLoginStatus(true);
                setTimerOn(false);
              });
          }
          return prevTimer - 1;
        });
      }, 1000);
    } else {
      setTimer(60 * 60); 
    }

    return () => clearInterval(interval);
  }, [timerOn, refreshToken, timer]);

  useEffect(() => {
    const loggedInStatus = sessionStorage.getItem("loggedIn");
    if (loggedInStatus) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleStatus = (status) => {
    if (status) {
      setTimerOn(false);
      setTimer(60 * 60);
      setTimerOn(true);
    } else {
      setTimerOn(false);
      setTimer(60 * 60);
      setLoginStatus(false);
    }
  };

  return (
    <div className="parent-box">
      {isTab ? (
        isLoggedIn ? ( // Check if user is logged in
          <Router>
            <div className="app-content">
              <Header
                menuOpen={(value) => {
                  setOpenMenu(value);
                }}
              />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/about" element={<AboutUsPage />} />
                <Route path="/gallery" element={<GalleryPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/blogs" element={<BlogPage />} />
                <Route
                  path="/join us"
                  element={
                    <JoinUsPage
                      loginStatus={loginStatus}
                      setStatus={handleStatus}
                    />
                  }
                />
                <Route path="/initiatives" element={<InitiativePage />} />
              </Routes>
              <div
                className={`header-menu ${openMenu ? "menu-show" : ""}`}
              ></div>
              <Footer />
            </div>
          </Router>
        ) : (
          <DeveloperLogin setIsLoggedIn={setIsLoggedIn} />
        )
      ) : (
        <div id="mobileMessage">
          This website is only accessible in mobile, desktop or desktop mode.
        </div>
      )}
    </div>
  );
}

export default App;
