import React from "react";
import "./styles/SpeakerModel.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function NextArrow(props) {
  return <div style={{ display: "none" }}></div>;
}

function PrevArrow(props) {
  return <div style={{ display: "none" }}></div>;
}

export default function SpeakerModel({ list }) {
  const slidesToShow = list.length < 4 ? list.length : 4;

  var settings = {
    infinite: list.length > 4, // Infinite scroll only if more than 1 item
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: list.length > 1, // Autoplay only if more than 1 item
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: list.length < 3 ? list.length : 3,
          slidesToScroll: 1,
          infinite: list.length > 1, // Infinite scroll only if more than 1 item
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="speaker-model-parent">
      <Slider {...settings}>
        {list.map((member, index) => (
          <div key={index} className="speaker-slider" style={{display:'flex', justifyContent:'center'}}>
            <div className="speaker-slide">
              <div className="speaker-profile-image-box">
                <img
                  src={member.image}
                  alt=""
                  className="speaker-profile-image"
                />
              </div>
              <div className="speaker-details">
                <h1 className="speaker-text">{member.name}</h1>
                <p className="body-text-md speaker-details-text">
                  {member.authority}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
