import React, { useState } from "react";
import "./page-styles/DeveloperLogin.css";

export default function DeveloperLogin({ setIsLoggedIn }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const validCredentials = {
    developer: 'dev123',
    guest: 'guest123',
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if ((username === 'developer' && password === validCredentials.developer) ||
        (username === 'guest' && password === validCredentials.guest)) {
      sessionStorage.setItem("loggedIn", true);
      setIsLoggedIn(true);
    } else {
      setError('Invalid username or password');
    }
  };

  return (
    <div className="login-container">
      <h2>Site Under Construction</h2>
      <p>Only developers and guests can access the site during construction.</p>
      <form onSubmit={handleLogin} className="login-form">
        <label htmlFor="username">Username:</label>
        <input
          type="text"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Enter your username"
          required
        />

        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter your password"
          required
        />

        {error && <div className="error-message">{error}</div>}

        <button type="submit">Login</button>
      </form>
    </div>
  );
}
