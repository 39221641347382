import React, { useState } from 'react';
import { Container, Typography, Grid, Card, CardMedia, CardContent, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';



// Sample static event data
const eventData = [
     {
        id: 1,
        name: 'Outreach Program',
        images: [
            'images/outreach/pic1.jpg',
            'images/outreach/pic2.jpg',
            'images/outreach/pic3.jpg',
            'images/outreach/pic4.jpg',
            'images/outreach/pic5.jpg',
            'images/outreach/pic6.jpg',
            'images/outreach/pic7.jpg',
        ],
        description: 'Description of Event 1',
    },
    {
        id: 2,
        name: 'Startup Mahakumbh',
        images: [
            'images/mahakumbh/pic1.jpg',
            'images/mahakumbh/pic2.jpg',
            'images/mahakumbh/pic3.jpg',
            'images/mahakumbh/pic4.jpg',
            'images/mahakumbh/pic5.jpg',
            'images/mahakumbh/pic6.jpg',
            'images/mahakumbh/pic7.jpg',
        ],
        description: 'Description of Event 2',
    },
    // Add more event data as needed
];



function GalleryPage() {
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleCardClick = (event) => {
        setSelectedEvent(event);
    };

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleCloseDialog = () => {
        setSelectedEvent(null);
    };

    const handleCloseImageDialog = () => {
        setSelectedImage(null);
    };

    return (
        <div style={{ width: '100%',  display: 'flex', flexDirection: 'column', minHeight: '100vh', paddingTop: '200px', backgroundImage: 'url(/images/Backgrounds/darkSky1.jpg)',
        backgroundSize: 'cover', // Ensure the background image covers the entire div
      backgroundRepeat: 'no-repeat', // Prevent the background image from repeating
      backgroundAttachment:'fixed' }}>
            <Container maxWidth="lg">
                <Grid marginTop={20} container spacing={3}>
                    {eventData.map((event) => (
                        <Grid item key={event.id} xs={12} md={6}>
                            <Card onClick={() => handleCardClick(event)}>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={event.images[0]} // Displaying only the first image as a thumbnail
                                    alt={event.name}
                                />
                                <CardContent style={{backgroundColor:'#33485E'}}>
                                    <Typography  style={{
                                        fontFamily: 'Oswald,sans-serif',
                                        fontWeight: 'bold'
                                    }} variant="h4" component="h2">{event.name}</Typography>
                                    {/* <Typography className={classes.HeadText} style={{
                                        fontFamily: 'Oswald,sans-serif',
                                        fontWeight: 'bold'
                                    }} variant="body1" color="text.secondary">{event.description}</Typography> */}
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Dialog open={Boolean(selectedEvent)} onClose={handleCloseDialog}>
                    <DialogTitle>{selectedEvent && selectedEvent.name}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            {selectedEvent && selectedEvent.images.map((image, index) => (
                                <Grid item key={index} xs={6} sm={4} md={3}>
                                    <Card onClick={() => handleImageClick(image)}>
                                        <CardMedia
                                            component="img"
                                            height="100"
                                            image={image}
                                            alt={`Event ${index + 1}`}
                                        />
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Close</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={Boolean(selectedImage)} onClose={handleCloseImageDialog} maxWidth="xl">
                    <DialogContent>
                        <img src={selectedImage} alt="" style={{ width: '100%', height: 'auto' }} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseImageDialog}>Close</Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </div>
    );
}

export default GalleryPage;
