import React, { useState, useEffect } from "react";
import "./page-styles/BlogsPage.css";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import BlogCard from "../components/BlogCard";
import { getBlog } from "../apis/APIhandler";

function BlogPage() {
  const [blogs, setBlogs] = useState([{}]);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    try {
      getBlog()
        .then(function (response) {
          // handle success
          setBlogs(response.data.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching approved members:", error);
    }
  }, []);

  const handleCardClick = (blog) => {
    setSelectedBlog(blog);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setSelectedBlog(null);
    setDialogOpen(false);
  };

  return (
    <div className="blog-parent">
      <div className="blog-section-first"></div>

      <div className="blog-box">
        <div className="box-heading">
          <div className="start-color"></div>
          <h1 className="heading-text-md">Blogs</h1>
        </div>
        <div className="blog-container">
          {blogs.map((blog, index) => (
            <BlogCard key={index} data={blog}/>
          ))}
        </div>
      </div>

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{selectedBlog?.Title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{selectedBlog?.Content}</DialogContentText>
          <Typography variant="subtitle2">
            Author: {selectedBlog?.Author}
          </Typography>
          <Typography variant="subtitle2">
            Date: {selectedBlog?.Date}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default BlogPage;
