import React from 'react';
import { Container, Typography, Grid, Paper } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';



function AboutUsPage() {

    return (
        <div style={{ 
            width: '100%', 
            display: 'flex', 
            flexDirection: 'column', 
            minHeight: '100vh', 
            paddingTop: '200px', 
            backgroundImage: 'url(/images/Backgrounds/blueSky.jpg)',
            backgroundSize: 'cover', // Ensure the background image covers the entire div
            backgroundRepeat: 'no-repeat', // Prevent the background image from repeating
            backgroundAttachment:'fixed'
          }}>
            <Container maxWidth="lg">
                <Grid padding={10} minHeight="100vh">
                <Typography variant="h2"  style={{fontFamily:'Oswald', fontWeight:'bold'}} align="center" gutterBottom>About Our Startup Cell</Typography>
                <Typography variant='h4'  style={{fontFamily:'Oswald', fontWeight:'normal' ,marginTop:'50px'}} align="center" gutterBottom>Startups drive economic growth through innovation, job creation, and investment, vital for a country's development.</Typography>
                </Grid>
                <Grid container spacing={3} marginTop={10}>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={7} style={{ height:'100%', padding: '20px', backgroundColor: 'rgb(51, 72, 94)' }}>
                            <Typography variant="h4" style={{fontFamily:'Oswald', fontWeight:'bold',padding:'30px'}} align="center" gutterBottom>Our Mission</Typography>
                            <Typography variant="h5" style={{fontFamily:'Oswald', fontWeight:'normal', color:'white'}}>
                            Our mission is to empower promising startups by providing customized programs, mentorship, and networking opportunities. By doing so, we aim to help them unlock their full potential and create meaningful impact globally. Through tailored support and access to resources, we nurture innovation and entrepreneurship, fostering a thriving community of change-makers dedicated to making a positive difference in the world.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={7} style={{height:'100%', padding: '20px', backgroundColor: 'rgb(51, 72, 94)' }}>
                            <Typography variant="h4"  style={{fontFamily:'Oswald', fontWeight:'bold', padding:'30px'}} align="center" gutterBottom>Our Vision</Typography>
                            <Typography variant="h5" style={{fontFamily:'Oswald', fontWeight:'normal', color:'white'}}>
                            Our Vision is about fostering an environment where startups thrive, emphasizing creativity, collaboration, and innovation. By doing so,
                              it aims to fuel economic growth and make a positive impact on society.
                               It envisions a dynamic ecosystem where entrepreneurs are supported and
                                encouraged to bring their ideas to life, ultimately benefiting both the
                                 economy and the community.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
                <Typography variant="h3" style={{fontFamily:'Oswald', fontWeight:'bold'}} align="center" gutterBottom marginTop={40 } marginBottom={5}>Our Timeline</Typography>
                <Timeline align="alternate">
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="primary" />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography variant="h6" component="span" color="white">Established Startup Cell</Typography>
                            <Typography color="white">2023</Typography>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="primary" />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography variant="h6" component="span" color="white">Launched First Product</Typography>
                            <Typography color="white">2023</Typography>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineDot color="primary" />
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography variant="h6" component="span" color="white">Holding 6th Rank in Startup Bihar across all Startup cell Bihar</Typography>
                            <Typography color="white" marginBottom={20}>2024</Typography>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            </Container>
        </div>
    );
}

export default AboutUsPage;
