import React from "react";
import "./styles/ProfileCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export default function ProfileCard({ details }) {
  return (
    <div className="profile-card">
      <div className="profile-card-image-box">
        <img
          src={details.image}
          alt=""
          className="profile-card-image"
        />
      </div>
      <div className="card-details">
        <h1 className="card-text">{details.name}</h1>
        <p className="body-text-md card-details-text">{details.role}</p>
        <div className="card-social-link">
          <a href="mailto:shivmouryacse@gmail.com" color="inherit">
            <FontAwesomeIcon icon={faEnvelope} size="lg" />
          </a>
          <a
            href="https://www.linkedin.com/in/e-cell-lnjpit-chapra-464a21287"
            color="inherit"
          >
            <FontAwesomeIcon icon={faLinkedin} size="lg" />
          </a>
          <a href="https://twitter.com/ecelllnjpit" color="inherit">
            <FontAwesomeIcon icon={faTwitter} size="lg" />
          </a>
          <a
            href="https://www.instagram.com/startupcell_lnjpit/"
            color="inherit"
          >
            <FontAwesomeIcon icon={faInstagram} size="lg" />
          </a>
        </div>
      </div>
    </div>
  );
}
