import axios from "axios";

function getNotification() {
  return axios.get("https://api.startupcelllnjpitchapra.in/data/get-notification");
}

function getNotice() {
  return axios.get("https://api.startupcelllnjpitchapra.in/data/get-notice");
}

function getInitiatives() {
  return axios.get("https://api.startupcelllnjpitchapra.in/data/get-initiative");
}

function getBlog() {
  return axios.get("https://api.startupcelllnjpitchapra.in/data/get-blog");
}

function getTestimonialData() {
  return axios.get("https://api.startupcelllnjpitchapra.in/profile/get-testimonial");
}

function getSpeakerList() {
  return axios.get("https://api.startupcelllnjpitchapra.in/profile/get-speaker");
}

function getAccessToken(refreshToken) {
  return axios.post("https://api.startupcelllnjpitchapra.in/profile/account/get-refresh-token", {
    token: refreshToken,
  });
}

function getUserData(accessToken) {
  return axios.post(
    "https://api.startupcelllnjpitchapra.in/profile/account/get-user",
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`, // Use the correct casing here
      },
    }
  );
}

function logout(accessToken, data) {
  return axios.post("https://api.startupcelllnjpitchapra.in/profile/account/logout-user", data, {
    headers: {
      Authorization: `Bearer ${accessToken}`, // Use the correct casing here
    },
  });
}

export {
  getNotification,
  getNotice,
  getInitiatives,
  getBlog,
  getTestimonialData,
  getSpeakerList,
  getAccessToken,
  getUserData,
  logout
};
