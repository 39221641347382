import React, { useState, useEffect } from "react";
import "./styles/Header.css";
import { useNavigate, useLocation } from "react-router-dom";
import { IoCaretDown } from "react-icons/io5";

const Header = ({ menuOpen }) => {
  const [activeLink, setActiveLink] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let path = location.pathname.substring(1); // get the path without the leading '/'
    if (path === "about") {
      path = "About Us";
    } else {
      path = path.charAt(0).toUpperCase() + path.slice(1);
    }
    setActiveLink(path || "Home"); // capitalize first letter and set default to 'Home'
  }, [location]);

  const handleNavigation = (page) => {
    setActiveLink(page);
    if (page === "About Us") {
      navigate("/about");
    } else {
      navigate("/" + page.toLowerCase());
    }
  };

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
    menuOpen(!openMenu);
    console.log(!openMenu);
  };

  return (
    <div className="header-class">
      <div className="header-logo">
        <img src="/images/Ecell-logos/logo_e-cell.jpg" alt="E-Cell Logo" />
        <h1>Startup Cell LNJPIT Chapra</h1>
      </div>

      <div className="nav-link-class">
        <div className={`nav-link`} onClick={() => handleNavigation("About")}>
          <span>About</span>
        </div>
        <div className={`nav-link`}>
          <span>Initiatves&nbsp;{<IoCaretDown />}</span>
          <div className="menu-options">
            <span>&nbsp; &bull; &nbsp; Outreach Event Program</span>
            <span>&nbsp; &bull; &nbsp; Avishkar Yojana</span>
            <span>&nbsp; &bull; &nbsp; Startup Internship Yojana</span>
            <span>&nbsp; &bull; &nbsp; Srijanika Pratiyogita</span>
            <span>&nbsp; &bull; &nbsp; Margdarshan Yojana</span>
            <span>&nbsp; &bull; &nbsp; Prarambh Shivir</span>
            <span>&nbsp; &bull; &nbsp; Workshops & Seminars</span>
          </div>
        </div>
        <div className={`nav-link`}>
          <span>IIC&nbsp;{<IoCaretDown />}</span>
          <div className="menu-options">
            <span>&nbsp; &bull; &nbsp; Registration Process</span>
            <span>&nbsp; &bull; &nbsp; Falana</span>
            <span>&nbsp; &bull; &nbsp; Dimkana</span>
            <span>&nbsp; &bull; &nbsp; Mam se Pucho</span>
            <span>&nbsp; &bull; &nbsp; Aage ka nhi Pata</span>
            <span>&nbsp; &bull; &nbsp; Isiliye</span>
            <span>&nbsp; &bull; &nbsp; Kuchh bhi likh diya</span>
          </div>
        </div>
        <div className={`nav-link`} onClick={() => handleNavigation("Gallery")}>
          <span>Resources&nbsp;{<IoCaretDown />}</span>
          <div className="menu-options">
            <span>&nbsp; &bull; &nbsp; Gallery</span>
            <span>&nbsp; &bull; &nbsp; Blogs</span>
          </div>
        </div>
        <div className={`nav-link`} onClick={() => handleNavigation("Blogs")}>
          <span>Startup&nbsp;{<IoCaretDown />}</span>
          <div className="menu-options">
            <span>&nbsp; &bull; &nbsp; Register Idea</span>
            <span>&nbsp; &bull; &nbsp; Status</span>
            <span>&nbsp; &bull; &nbsp; Registration Process</span>
          </div>
        </div>
      </div>

      <div className="header-btn-class">
        <button onClick={() => handleNavigation("Contact")}>Contact</button>
      </div>

      <div className="menu-icon" onClick={handleOpenMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Header;
