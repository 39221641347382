import React from "react";
import "./styles/InitiativeSlider.css";

export default function InitiativeSlider({ initiativeData }) {
  return (
    <div className="Initiative-slider-wrapper">
      {initiativeData.map((data, index) => (
        <div key={index} className="Initiative-slide">
          <h6>{data.title}</h6>
          <span>{data.details}</span>
        </div>
      ))}
    </div>
  );
}
