import React, { useEffect, useState } from "react";
import axios from "axios";
import "./styles/LoginSignup.css";
import { FaUser } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";

export default function LoginSignup({ setStatus}) {
  const [active, setActive] = useState("");
  const [otp, setOtp] = useState("");
  const [otpVerify, setOtpVerify] = useState(false);
  const [timer, setTimer] = useState(60);
  const [timerOn, setTimerOn] = useState(false);
  const [loginData, setLoginData] = useState({ id: "", password: "" });
  const [registerData, setRegisterData] = useState({
    name: "",
    email: "",
    otp: "",
  });
  
  const registerLink = () => {
    setActive("active");
  };

  const loginLink = () => {
    setActive("");
  };

  const handleInputChange = (event, setData) => {
    const { name, value } = event.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://api.startupcelllnjpitchapra.in/profile/account/login-user",
        loginData
      );
      if (response.status === 200) {
        const refreshToken = response.data.refreshToken;
        const accessToken = response.data.accessToken;
        
        localStorage.setItem("refreshToken", refreshToken);
        sessionStorage.setItem("accessToken", accessToken);
        
        // Handle successful login, e.g., redirect, set user context, etc.
        setStatus(true);
        alert("Login Successful");
      }
    } catch (error) {
      // Handle login error
      console.error("Login error", error);
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    if (otpVerify) {
      try {
        const response = await axios.post(
          "https://api.startupcelllnjpitchapra.in/profile/account/register-user",
          registerData
        );
        // Handle successful registration, e.g., redirect to login
        console.log(response.data);
      } catch (error) {
        if (error.request.status === 409) {
          alert("Email Already Registered");
        }
      }
    } else {
      alert("Please Verify OTP");
    }
  };

  const handleForgetPassword = () => {
    // Handle forgot password logic here
    console.log("Clicked");
  };

  useEffect(() => {
    let interval;
    if (timerOn) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(interval);
            setTimerOn(false);
          }
          return prevTimer - 1;
        });
      }, 1000);
    } else {
      setTimer(60); // Reset timer to 60 seconds when timerOn is false
    }

    return () => clearInterval(interval);
  }, [timerOn]);

  const handleGetOtp = async () => {
    if (registerData.email !== "" && !timerOn) {
      setTimerOn(true); // Start the timer
      try {
        const response = await axios.post(
          "https://api.startupcelllnjpitchapra.in/profile/account/get-otp",
          {
            email: registerData.email,
          }
        );
        if (response.status === 200) {
          const generatedOtp = response.data.otp; // Assume OTP is returned in response.data.otp
          setOtp(generatedOtp);
          console.log("OTP sent successfully", generatedOtp);
        } else {
          setTimerOn(false); // Start the timer
          console.error("OTP request failed");
        }
      } catch (error) {
        // Handle OTP request error
        setTimerOn(false);
        console.error("OTP request error", error);
      }
    } else {
      alert("Please enter email");
      setTimerOn(false);
    }
  };

  const handleVerifyOtp = () => {
    if (!otpVerify) {
      if (registerData.otp === otp && registerData.otp !== "") {
        setOtpVerify(true);
        alert("OTP Verified");
      } else {
        alert("Incorrect OTP");
      }
    }
  };

  
  return (
    <div className={`auth-form-model ${active}`}>
      <div className="form-box login">
        <form onSubmit={handleLogin}>
          <h1>Login</h1>
          <div className="input-box">
            <input
              type="text"
              name="id"
              placeholder="Login Id"
              value={loginData.id}
              onChange={(e) => handleInputChange(e, setLoginData)}
              required
            />
            <FaUser className="icon" />
          </div>
          <div className="input-box">
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={loginData.password}
              onChange={(e) => handleInputChange(e, setLoginData)}
              required
            />
            <RiLockPasswordFill className="icon" />
          </div>
          <div className="remember-forgot">
            <a href="#forgot" onClick={handleForgetPassword}>
              Forgot Password?
            </a>
          </div>
          <button type="submit" className="lf-btn">Login</button>
          <div className="register-link">
            <p>
              Don't have an account?{" "}
              <a href="#sign-up" onClick={registerLink}>
                Register
              </a>
            </p>
          </div>
        </form>
      </div>

      <div className="form-box signup">
        <form onSubmit={handleRegister}>
          <h1>Sign Up</h1>
          <div className="input-box">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={registerData.name}
              onChange={(e) => handleInputChange(e, setRegisterData)}
              required
            />
            <FaUser className="icon" />
          </div>
          <div className="input-box">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={registerData.email}
              onChange={(e) => handleInputChange(e, setRegisterData)}
              required
            />
            <span onClick={handleGetOtp}>
              {timerOn ? `${timer}` : "Get OTP"}
            </span>
          </div>
          <div className="input-box">
            <input
              type="text"
              name="otp"
              placeholder="OTP"
              value={registerData.otp}
              onChange={(e) => handleInputChange(e, setRegisterData)}
              required
            />
            <span onClick={handleVerifyOtp}>
              {otpVerify ? "Verified" : "Verify OTP"}
            </span>
          </div>
          <div className="remember-forgot">
            <label>
              <input type="checkbox" />I agree to the <a href="#terms">terms</a>{" "}
              & <a href="#conditions">conditions</a>
            </label>
          </div>
          <button type="submit" className="lf-btn">Register</button>
          <div className="register-link">
            <p>
              Already have an account?{" "}
              <a href="#create-account" onClick={loginLink}>
                Login
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
}
