import React, { useEffect, useState } from "react";
import "./page-styles/JoinUsPage.css";
import Header from "../components/Header";
import ProfileModel from "../components/ProfileModel";
import LoginSignup from "../forms/LoginSignup";
import { getUserData, logout } from "../apis/APIhandler";
import Snackbox from "../utils/Snackbox";
import { FaHandPointRight } from "react-icons/fa";
import { FaLinkSlash } from "react-icons/fa6";

export default function JoinUsPage({ loginStatus, setStatus }) {
  const [login, setLogin] = useState(false);
  const [userData, setUserData] = useState({});
  const [snackMessage, setSnackMessage] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  let accessToken = sessionStorage.getItem("accessToken");
  useEffect(() => {
    setLogin(loginStatus);
    if (login) {
      getUserData(accessToken)
        .then((response) => {
          setUserData(response.data.userData);
          console.log(response.data);
        })
        .catch((error) => {
          setOpenSnack(true);
          setSnackMessage("Network error please refresh page");
        });
    }
  }, [loginStatus, login, accessToken]);

  const handleLogout = () => {
    logout(accessToken, { login: false })
      .then((response) => {
        console.log(response);
        setLogin(false);
        setStatus(false);
        localStorage.setItem("refreshToken", "null");
        sessionStorage.setItem("accessToken", "null");
      })
      .catch((error) => {
        setLogin(true);
        setStatus(true);
        console.log(error);
      });
  };
  return (
    <div className="join-us-page-parent">
      <div className="top-content">
        <div className="parent-div">
          {!login && (
            <div className="auth-form">
              <LoginSignup
                setStatus={(status) => {
                  setStatus(status);
                  setLogin(status);
                }}
              />
            </div>
          )}
          <div className="join-us-page-top-details">
            <h1 className="font-lg color-white letter-spacing-1 word-spacing-4">
              Road to Futurpreneur
            </h1>
            <h3 className="custom-text">
              Embark on the Road to Futurepreneur, where innovation meets
              ambition, and today's visionary ideas evolve into tomorrow's
              thriving enterprises.
            </h3>
          </div>
        </div>
      </div>
      <div className="bottom-content">
        <div className="left-content">
          <div className="left-content-top"></div>
          <div className="left-content-section1"></div>
        </div>
        <div className="right-content">
          <ProfileModel
            onClick={handleLogout}
            loginStatus={login}
            data={userData}
          />
          <div className="link-box">
            <h4>
              {" "}
              <FaLinkSlash />
              Important Links
            </h4>
            <br />
            {/* <span><FaHandPointRight/><a href="#link">Apply</a> for startup-cell memebership.</span>
            <span><FaHandPointRight/><a href="#link">Register</a> your idea.</span>
            <span><FaHandPointRight/><a href="#link">Register</a> in our internship program.</span> */}
          </div>
        </div>
      </div>
      <Snackbox
        message={snackMessage}
        onOpen={openSnack}
        onClose={() => {
          setOpenSnack(false);
        }}
      />
    </div>
  );
}
