import React from "react";
import "./styles/BlogCard.css";

export default function BlogCard({ data }) {
  const formatDateToIST = (dateString) => {
    // Parse the date string to a Date object
    const date = new Date(dateString);

    // Convert to IST (Indian Standard Time)
    const ISTOffset = 5.5 * 60 * 60 * 1000; // IST is UTC+5:30
    const ISTDate = new Date(date.getTime() + ISTOffset);

    // Format the time to 'HH:MM:SS AM/PM'
    let hours = ISTDate.getUTCHours();
    const minutes = ISTDate.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes} ${ampm}`;

    // Format the date to '6 July 2024'
    const day = ISTDate.getUTCDate();
    const month = ISTDate.toLocaleString("default", { month: "long" });
    const year = ISTDate.getUTCFullYear();

    return `${strTime}, ${day} ${month} ${year}`;
  };
  return (
    <div className="blog-card">
      <div className="blog-card-image-container">
        <img src={data.image} alt="" className="blog-card-image" />
      </div>
      <div className="blog-card-details">
        <div className="blog-card-heading">
          <h1 className="blog-title">{data.title}</h1>
        </div>
        <div className="blog-card-footer">
          <div className="blog-card-footer-details">
            <div className="blog-card-logo">
              <img
                src="/images/Ecell-logos/Startup-cell.png"
                alt=""
                className="blog-card-logo-img"
              />
            </div>
            <div className="blog-card-author-details">
              <p className="blog-card-text-md">Start-up Cell LNJPIT Chapra</p>
              <p className="blog-card-text-sm">Author: {data.authorName}</p>
              <p className="blog-card-text-sm">
                Publish date: {formatDateToIST(data.publishDate)}
              </p>
            </div>
          </div>
          <div className="blog-card-footer-icons"></div>
        </div>
      </div>
    </div>
  );
}
