import React from 'react';
import { Grid, Typography, Link} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Footer() {
    return (
        <footer style={{ backgroundColor: '#33485E', padding: '20px'}}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} md={4}>
                    <Typography variant="h6" style={{ color: 'white', marginBottom: '10px' }}>
                        Follow Us
                    </Typography>
                    <Link href="https://www.facebook.com/profile.php?id=100095283836380" color="inherit" style={{ marginRight: '10px' }}>
                        <FontAwesomeIcon icon={faFacebook} size="lg" />
                    </Link>
                    <Link href="https://twitter.com/ecelllnjpit" color="inherit" style={{ marginRight: '10px' }}>
                        <FontAwesomeIcon icon={faTwitter} size="lg" />
                    </Link>
                    <Link href="https://www.instagram.com/startupcell_lnjpit/" color="inherit" style={{ marginRight: '10px' }}>
                        <FontAwesomeIcon icon={faInstagram} size="lg" />
                    </Link>
                    <Link href="https://www.linkedin.com/in/e-cell-lnjpit-chapra-464a21287" color="inherit">
                        <FontAwesomeIcon icon={faLinkedin} size="lg" />
                    </Link>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h6" style={{ color: 'white', marginBottom: '10px' }}>
                        Developer Contact
                    </Typography>
                    <Link href="mailto:shivmouryacse@gmail.com" color="inherit" style={{ marginRight: '10px' }}>
                        <FontAwesomeIcon icon={faEnvelope} size="lg" />
                    </Link>

                    <Link href="https://www.linkedin.com/in/shivrajit-kushwaha-595494319/" color="inherit" style={{ marginRight: '10px' }}>
                        <FontAwesomeIcon icon={faLinkedin} size="lg" />
                    </Link>
                </Grid>
            </Grid>

            <Typography variant="body2" style={{ color: 'white', textAlign: 'center', marginTop: '20px' }}>
                &copy; 2024 Start-Up Cell
            </Typography>
        </footer>
    );
}

export default Footer;