import { React, useEffect, useState } from "react";
import "./page-styles/ContactPage.css";
import "@fortawesome/fontawesome-free/css/all.css";
import { Typography } from "@mui/material";
import ProfileCard from "../components/ProfileCard";

function ContactPage() {
  const [Head, setHead] = useState([]);
  useEffect(() => {
    // const fetchData = async () => {
    //     try {

    //         const response = await fetch('https://back2.agamyatech.in/Server/getMembers', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //               },
    //               body: JSON.stringify({ AuthenticationToken: 'StartupCell2012to2024' })
    //         });

    //         if (response.ok) {
    //             const responseData = await response.json();
    //             if (responseData && responseData.status === "Success") {
    //                 setHead(responseData.HeadData || []);
    //                 setOHead(responseData.OverallHeadData || []);
    //                 setSR(responseData.SRData || []);
    //                 setSC(responseData.SC || []);
    //             } else {
    //                 alert('Registration failed. Please try again later.');
    //             }
    //         } else {
    //             throw new Error('Failed to submit form');
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    //     }
    // };

    // fetchData();
    setHead([
      { name: "Abhishek Paswan", role: "Faculty In-Charge", image: "/images/Members/abhishek.jpg" },
      { name: "Surbhi Singh", role: "Student Coordinator", image: "/images/Members/Surbhi-Singh.jpg" },
      { name: "Aryan Chauhan", role: "Student Representative", image: "/images/Members/Aryan-Chauhan.png" },
      { name: "Prince Gaurav", role: "Student Representative", image: "/images/Members/prince.jpg" },
      { name: "Shivrajit Kushwaha", role: "Web & Technical Head", image: "/images/Members/shiv.jpg" },
      { name: "Akanksha Priya", role: "Event Planer", image: "/images/Members/Akanksha-Priya.jpg" },
      { name: "Akanksha Priya", role: "Desginer Head", image: "/images/Members/Sahil.jpg" },
      { name: "Som Singh", role: "Product Lead", image: "/images/Members/Som.jpg" },
      { name: "Harsh Raj", role: "PR & External", image: "/images/Members/harsh.jpg" },
    ]);
  }, []);

  return (
    <div
      className="body"
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <section className="section1">
        <div className="section1-content">
          <Typography
            variant="h2"
            style={{
              fontFamily: "Oswald,sans-serif",
              fontWeight: "bold",
              color: "#bd9f67",
            }}
          >
            GET IN TOUCH WITH US
          </Typography>
          <Typography
            variant="h5"
            style={{ fontFamily: "Oswald,sans-serif", color: "#bd9f67" }}
          >
            We're here to help and answer any questions you may have
          </Typography>
        </div>
      </section>

      <div className="section2-content">
        <div className="head-list">
          {Head.map((member, index) => (
            <ProfileCard key={index} details={member} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
