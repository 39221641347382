import React,{useEffect, useState} from 'react';
import { Container, Typography, Grid, Card, CardContent } from '@mui/material';


function InitiativePage() {

    const [initiativesData,setInitiativesData] = useState([])

    useEffect(() => {
        try {
            // Fetch approved members from the server
            fetch('https://back2.agamyatech.in/Initiative/getData', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Failed to publish blog');
                    }
                })
                .then(Data => {
                    if (Data.status === "Success") {
                        console.log(Data.data);
                        setInitiativesData(Data.data)
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        } catch (error) {
            console.error('Error fetching approved members:', error);
        }
    }, []);
    
    return (
        <div style={{ width: '100%',  display: 'flex', flexDirection: 'column', minHeight: '100vh',
         backgroundImage: 'url(/images/Backgrounds/darkSky1.jpg)',
          backgroundSize: 'cover', // Ensure the background image covers the entire div
        backgroundRepeat: 'no-repeat', // Prevent the background image from repeating
        backgroundAttachment:'fixed', paddingTop: '200px' }}>
            <Container maxWidth="lg">
                <Grid container spacing={3} justifyContent={'center'}>
                    {initiativesData.map((initiative,index) => (
                        <Grid item key={index + 1} xs={12} sm={12} md={12}>
                            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                <CardContent >
                                    <Typography variant="h3" component="h2" style={{fontFamily:'Oswald', color: 'orange', textAlign:'center',marginBottom:'40px',fontWeight:'bold'}}>{initiative.Title}</Typography>
                                    <Typography variant="body1" color="text.secondary" style={{ color: 'black', padding:'40px' }}>{initiative.Details}</Typography>
                                    <Card
                                    component="img"
                                    height="200"
                                    src={"images/outreach/pic1.jpg"} // Displaying only the first image as a thumbnail
                                    alt={'Event Image'}
                                    style={{marginRight:'10px'}}
                                    >
                                    
                                    </Card>
                                    <Card
                                    component="img"
                                    height="200"
                                    src={"images/outreach/pic2.jpg"} // Displaying only the first image as a thumbnail
                                    alt={'Event Image'}
                                    style={{marginRight:'10px'}}>

                                    </Card>
                                    <Card
                                    component="img"
                                    height="200"
                                    src={"images/outreach/pic3.jpg"} // Displaying only the first image as a thumbnail
                                    alt={'Event Image'}
                                    style={{marginRight:'10px'}}>

                                    </Card>
                                    <Card
                                    component="img"
                                    height="200"
                                    src={"images/outreach/pic4.jpg"} // Displaying only the first image as a thumbnail
                                    alt={'Event Image'}
                                    style={{marginRight:'10px'}}>
                                    </Card>

                                    <Card
                                    component="img"
                                    height="200"
                                    src={"images/outreach/pic5.jpg"} // Displaying only the first image as a thumbnail
                                    alt={'Event Image'}
                                    style={{marginRight:'10px'}}>
                                    </Card>

                                    <Card
                                    component="img"
                                    height="200"
                                    src={"images/outreach/pic6.jpg"} // Displaying only the first image as a thumbnail
                                    alt={'Event Image'}
                                    style={{marginRight:'10px'}}>
                                    </Card>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
        
    );
}

export default InitiativePage;
