import React from "react";
import "./styles/ProfileModel.css";


export default function ProfileModel({ onClick, loginStatus, data }) {
  return (
    <div className="profile-model-parent">
      <div className="profile-image-container">
        <img
          src={`${
            data.image ? `${data.image}` : "/images/logos/man.png"
          }`}
          alt="profile pic"
        />
        {loginStatus ? (
          <div className="user-name">
            <h1 className="heading-text-md">Welcome,</h1>
            <p className="heading-text-sm">Mr. {data.name}</p>
            <button onClick={onClick}>Log Out</button>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
          </div>
        )}
      </div>
    </div>
  );
}
