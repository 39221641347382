import React, { useState, useEffect } from "react";
import { Box, Typography, Avatar, Paper, Fade } from "@mui/material";

const TestimonialContainer = ({ testimonials }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setChecked(false); // Start fade out
      setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length); // Update index
        setChecked(true); // Start fade in
      }, 200); // Duration of fade-out transition
    }, 8000);

    return () => clearInterval(intervalId);
  }, [testimonials.length]);

  return (
    <Box sx={{ flexGrow: 1, position: "relative", height: "100%", width:'500px', marginTop:'10vh' }}>
      <Fade in={checked} timeout={{ enter: 500, exit: 500 }}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <Paper
            elevation={7}
            sx={{
              p: 2,
              textAlign: "center",
              borderRadius: "10px",
              bgcolor: "#F2C0BB",
            }}
          >
            <Avatar
              alt={testimonials[activeIndex].name}
              src={testimonials[activeIndex].image}
              sx={{ width: 100, height: 100, margin: "auto" }}
            />
            <Typography
              variant="h6"
              sx={{
                mt: 2,
                fontFamily: "Oswald",
                fontWeight: "bold",
                color: "#EC614C",
                fontSize: "25px",
              }}
            >
              {testimonials[activeIndex].name}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", color: "orange", fontSize: "15px" }}
            >
              {testimonials[activeIndex].authority}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                textAlign: "start",
                fontFamily: "Oswald",
                color: "white",
                fontWeight: "normal",
                fontSize: "20px",
                padding: "20px",
              }}
            >
              "{testimonials[activeIndex].testimonial}"
            </Typography>
          </Paper>
        </Box>
      </Fade>
    </Box>
  );
};

export default TestimonialContainer;
