import React, { useEffect, useState } from "react";
import "./page-styles/Home.css"; // Create this CSS file for styling
import TestimonialContainer from "../components/TestimonialContainer"; // Import the TestimonialContainer component
import SpeakerModel from "../components/SpeakerModel";
import {
  getInitiatives,
  getNotification,
  getSpeakerList,
  getTestimonialData,
} from "../apis/APIhandler";
import InitiativeSlider from "../components/InitiativeSlider";


function Home() {
  const [notifications, setNotifications] = useState([]);
  const [initiatives, setInitiatives] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [speakerList, setSpeakerList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [upcomingEvent, setUpcomingEvent] = useState([]);
  const [ourParteners, setOurParteners] = useState([]);
  

  useEffect(() => {
    Promise.all([
      getNotification(),
      getInitiatives(),
      getTestimonialData(),
      getSpeakerList(),
    ])
      .then(function (results) {
        try {
          setNotifications(results[0].data.data);
          setInitiatives(results[1].data.data);
          setTestimonials(results[2].data.data);
          setSpeakerList(results[3].data.data);
        } catch (error) {
          console.error("There was an error parsing data!", error);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, []);
  useEffect(() => {
    // Set interval to update the current index every 15 seconds
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        return (prevIndex + 1) % notifications.length;
      });
    }, 15000); // 15000 ms = 15 seconds

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [notifications.length]); // Re-run effect when notifications.length changes
  return (
    <div className="home-page">
      <div className="startup-banner">
        <img src="/images/Backgrounds/Startup Cell.png" alt="" />
      </div>

      <div className="runnable-notification">
        {notifications.length && (
          <b className="body-text-md runnable-text">
            <img
              src="/images/logos/new.png"
              alt=""
              style={{ width: "40px", height: "40px", marginRight: "10px" }}
            />
            {notifications[currentIndex].notification}
          </b>
        )}
      </div>

      <div className="about-wrapper">
        <div className="heading-class">
          <img src="/images/Backgrounds/Ellipse-1.png" alt="" />
          <img src="/images/Backgrounds/Ellipse-2.png" alt="" />
          <h3>About Us</h3>
        </div>

        <div className="about-us-content">
          <h2>Startup Cell LNJPIT, Chapra</h2>
          <b>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. A quasi
            numquam eius doloremque tempora magnam cum nam, temporibus officia
            laborum vel amet? Optio nesciunt nemo provident illo cumque
            praesentium alias.
          </b>
          <h3>ENTREPRENEURS:</h3>
          <b>
            A 3-month incubation program that provides selected startups with
            office space, resources, and access to a network of professionals to
            help them develop their ideas into viable businesses. An initiative
            that connects students with startups for internships, offering
            hands-on experience in the startup environment and fostering
            practical skills in entrepreneurship
          </b>
        </div>
      </div>

      {initiatives.length > 0 ? (
        <div className="initiative-wrapper">
          <div className="heading-class">
            <img src="/images/Backgrounds/Ellipse-1.png" alt="" />
            <img src="/images/Backgrounds/Ellipse-2.png" alt="" />
            <h3>Initiatives</h3>
          </div>
          <div className="initiative-content">
            <InitiativeSlider initiativeData={initiatives} />
          </div>
        </div>
      ) : (
        ""
      )}

      {upcomingEvent.length > 0 ? (
        <div className="event-wrapper">
          <div className="heading-class">
            <img src="/images/Backgrounds/Ellipse-1.png" alt="" />
            <img src="/images/Backgrounds/Ellipse-2.png" alt="" />
            <h3>Upcoming Events</h3>
          </div>
          <div className="event-content">
            <div className="row-class">
              <div className="column">
                <img src="/images/Backgrounds/meetingBack.jpg" alt="" />
                <div className="event-details">
                  <h1>Outreach 1</h1>
                </div>
              </div>
              <div className="column">
                <img src="/images/Backgrounds/meetingBack.jpg" alt="" />
                <div className="event-details">
                  <h1>Outreach 2</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {speakerList.length > 0 ? (
        <div className="mentors-wrapper">
          <div className="heading-class">
            <img src="/images/Backgrounds/Ellipse-1.png" alt="" />
            <img src="/images/Backgrounds/Ellipse-2.png" alt="" />
            <h3>Our Mentors</h3>
          </div>

          <SpeakerModel list={speakerList} />
        </div>
      ) : (
        ""
      )}

      {testimonials.length > 0 ? (
        <div className="testimonial-wrapper">
          <div className="heading-class">
            <img src="/images/Backgrounds/Ellipse-1.png" alt="" />
            <img src="/images/Backgrounds/Ellipse-2.png" alt="" />
            <h3>Testimonial</h3>
          </div>
          <TestimonialContainer testimonials={testimonials} />
        </div>
      ) : (
        ""
      )}

      {ourParteners.length > 0 ? (
        <div className="supporter-wrapper">
          <div className="heading-class">
            <img src="/images/Backgrounds/Ellipse-1.png" alt="" />
            <img src="/images/Backgrounds/Ellipse-2.png" alt="" />
            <h3>Our Supporter</h3>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Home;
